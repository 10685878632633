import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Arbeiten mit Pools",
  "description": "Wie gewähre ich Teammitgliedern Zugriff auf eine bestimmte Stelle inklusive eingegangener Bewerbungen",
  "author": "Anna-Mai Petersen",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Unter /backend/pools können sie eigene Bewerberpool verwalten.`}</p>
    <p>{`Bitte füllen Sie Titel & Beschreibung und den Pool für ihre Kollegen möglichst einfach zu kennzeichnen.`}</p>
    <p>{`Sie haben zusätzlich die Möglichkeit auszuwählen ob der Pool auf der Startseite angezeigt werden soll. Markieren Sie dazu einfach das Feld "Promoted" und speichern Sie den Pool.`}</p>
    <p>{`Außerdem können Sie das Icon und die Farbe des Pools individuell festlegen.`}</p>
    <p>{`Mit dem Workflow Typ bestimmen Sie automatisierte Aktionen, sobald ein Bewerber zugeordnet worden ist.`}</p>
    <p>{`Aktuell stehen folgende Workflows zur Auswahl:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Decline: Beweber automatisiert per E-Mail absagen`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ice Letter: Bewerber um Aufnahme in Pool und längere Aufbewahrung bitten`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sync to NAV: Explizit zum Übertragen nach NAV markieren`}</p>
      </li>
    </ul>
    <p>{`Hire: Bewerber einstellen (wichtig um die Kennzahl "time to hire" zu berechnen)`}</p>
    <ul>
      <li parentName="ul">{`Notify followers: Alle Gremiumsmitglieder, sog. "Follower" über den Statusübergang benachrichtigen`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      